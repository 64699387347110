import { BBAuth } from '@blackbaud/auth-client';
import { isObject } from './typeguards';

export async function getBBToken() {
  try {
    return await BBAuth.getToken({
      forceNewToken: true,
      disableRedirect: true,
    });
  } catch (err) {
    if (!isBBAuthError(err)) {
      throw new Error(`Get token request failed: ${err}`);
    }

    return;
  }
}

function isBBAuthError(err: unknown) {
  if (!isObject(err)) {
    return false;
  }

  return err?.code === 1 && err?.message === 'The user is not logged in.';
}
